import React from 'react';

const CommonCheckbox = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 6C0.5 2.68629 3.18629 0 6.5 0H14.5C17.8137 0 20.5 2.68629 20.5 6V14C20.5 17.3137 17.8137 20 14.5 20H6.5C3.18629 20 0.5 17.3137 0.5 14V6Z"
      fill="#FCFCFD"
    />
    <path
      d="M15.1663 6.5L8.74967 12.9167L5.83301 10"
      stroke="#101828"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 1H14.5V-1H6.5V1ZM19.5 6V14H21.5V6H19.5ZM14.5 19H6.5V21H14.5V19ZM1.5 14V6H-0.5V14H1.5ZM6.5 19C3.73858 19 1.5 16.7614 1.5 14H-0.5C-0.5 17.866 2.63401 21 6.5 21V19ZM19.5 14C19.5 16.7614 17.2614 19 14.5 19V21C18.366 21 21.5 17.866 21.5 14H19.5ZM14.5 1C17.2614 1 19.5 3.23858 19.5 6H21.5C21.5 2.13401 18.366 -1 14.5 -1V1ZM6.5 -1C2.63401 -1 -0.5 2.13401 -0.5 6H1.5C1.5 3.23858 3.73858 1 6.5 1V-1Z"
      fill="#FCFCFD"
    />
  </svg>
);

export default CommonCheckbox;
